.sjp-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.sjp-item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ece9e6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffffff,
    #ece9e6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffffff,
    #ece9e6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient-background {
  background: #ece9e6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffffff,
    #ece9e6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffffff,
    #ece9e6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

h2 {
  color: #2176bc;
  font-weight: 700;
  line-height: 1.5em;
  /* font-size: 2vw; */
}

p {
  line-height: 2em;
  /* font-size: 1.1vw; */
}

li {
  line-height: 2em;
  /* font-size: 1.1vw; */
}

@media screen and (max-width: 576px) {
  h1 {
    font-weight: 700;
    line-height: 1.5em;
    font-size: 5vw;
  }

  h2 {
    color: #2176bc;
    font-weight: 700;
    line-height: 1.5em;
    font-size: 5vw;
  }

  h4 {
    font-size: 4vw;
  }

  p {
    line-height: 2em;
    font-size: 3vw;
  }

  li {
    line-height: 2em;
    font-size: 3vw;
  }

  span {
    line-height: 2em;
    font-size: 3vw;
  }
}

footer {
  background-color: #2176bc;
}

.page-container {
  text-align: center !important;
  margin-top: 80px !important;
}

.font-bold {
  font-weight: 600;
}

.text-italic {
  font-style: italic;
}

.text-xl {
  font-size: 48px;
}

.text-large {
  font-size: 36px;
}

.text-md {
  font-size: 24px;
}

.text-fair {
  font-size: 18px;
}

.text-green {
  color: #8ec540;
}

.bg-green {
  background-color: #8ec540;
}

.bg-blue {
  background-color: #2176bc;
}

.bg-grey {
  background-color: #f6f6f6;
}

.color-blue {
  color: #2176bc;
}

.color-white {
  color: #ffffff;
}

.button-outlined {
  background-color: #ffffff;
  border-color: #2176bc;
  color: #2176bc;
}

.button-green,
.button-outlined:hover {
  background-color: #8ec540;
  border-color: #8ec540;
  color: #ffffff;
}

.button-green:hover {
  background-color: #fff;
  color: #8ec540;
  border-color: #fff;
}

.mb-50 {
  margin-bottom: 80px;
}

.lg-responsive {
  font-size: 3vw;
}

.md-responsive {
  font-size: 2vw;
}

.center-item {
  margin: auto;
}

@media screen and (min-width: 576px) {
  .month-column {
    width: 150px;
  }

  .job-order-column {
    width: 135px;
  }

  .no-kontainer-column {
    width: 210px;
  }

  .stock-masuk-column {
    width: 140px;
  }

  .last-updated-column {
    width: 330px;
  }

  .description-column {
    width: 200px;
  }

  .free-description-column {
    min-width: 200px;
  }

  .users-column {
    width: 150px;
  }

  .free-users-column {
    min-width: 150px;
  }
}

@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ece9e6; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#ffffff),
    to(#ece9e6)
  );
  background: linear-gradient(
    to right,
    #ffffff,
    #ece9e6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient-background {
  background: #ece9e6; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#ffffff),
    to(#ece9e6)
  );
  background: linear-gradient(
    to right,
    #ffffff,
    #ece9e6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

h2 {
  color: #2176bc;
  font-weight: 700;
  line-height: 1.5em;
  /* font-size: 2vw; */
}

p {
  line-height: 2em;
  /* font-size: 1.1vw; */
}

li {
  line-height: 2em;
  /* font-size: 1.1vw; */
}

@media screen and (max-width: 576px) {
  h1 {
    font-weight: 700;
    line-height: 1.5em;
    font-size: 5vw;
  }

  h2 {
    color: #2176bc;
    font-weight: 700;
    line-height: 1.5em;
    font-size: 5vw;
  }

  h4 {
    font-size: 4vw;
  }

  p {
    line-height: 2em;
    font-size: 3vw;
  }

  li {
    line-height: 2em;
    font-size: 3vw;
  }

  span {
    line-height: 2em;
    font-size: 3vw;
  }
}

footer {
  background-color: #2176bc;
}

.page-container {
  text-align: center !important;
  margin-top: 80px !important;
}

.font-bold {
  font-weight: 600;
}

.text-italic {
  font-style: italic;
}

.text-xl {
  font-size: 48px;
}

.text-large {
  font-size: 36px;
}

.text-md {
  font-size: 24px;
}

.text-fair {
  font-size: 18px;
}

.text-green {
  color: #8ec540;
}

.bg-green {
  background-color: #8ec540;
}

.bg-blue {
  background-color: #2176bc;
}

.bg-grey {
  background-color: #f6f6f6;
}

.color-blue {
  color: #2176bc;
}

.color-white {
  color: #ffffff;
}

.button-outlined {
  background-color: #ffffff;
  border-color: #2176bc;
  color: #2176bc;
}

.button-green,
.button-outlined:hover {
  background-color: #8ec540;
  border-color: #8ec540;
  color: #ffffff;
}

.button-green:hover {
  background-color: #fff;
  color: #8ec540;
  border-color: #fff;
}

.mb-50 {
  margin-bottom: 80px;
}

.lg-responsive {
  font-size: 3vw;
}

.md-responsive {
  font-size: 2vw;
}

.center-item {
  margin: auto;
}

@media screen and (min-width: 576px) {
  .month-column {
    width: 150px;
  }

  .job-order-column {
    width: 135px;
  }

  .no-kontainer-column {
    width: 210px;
  }

  .stock-masuk-column {
    width: 140px;
  }

  .last-updated-column {
    width: 330px;
  }

  .description-column {
    width: 200px;
  }

  .free-description-column {
    min-width: 200px;
  }

  .users-column {
    width: 150px;
  }

  .free-users-column {
    min-width: 150px;
  }
}

.active:hover {
  color: #8ec540;
  font-weight: 600;
}
.footer-link {
  text-decoration: none;
  color: #ffffff;
}

.footer-link:hover {
  color: #ffffff;
}

.icon::after {
  font-family: 'FontAwesome';
  cursor: pointer;
  color: #8ec540;
  font-size: 28px;
  margin-right: 8px;
}

.facebook-icon::after {
  content: '\f082';
}

.twitter-icon::after {
  content: '\f099';
}

.youtube-icon::after {
  content: '\f167';
}

.instagram-icon::after {
  content: '\f16d';
}

.contact-div {
  color: white;
}

.testing-center {
  vertical-align: middle;
  margin-left: 8px;
  height: 28px;
  width: 28px;
  -webkit-filter: invert(100%) sepia(1%) saturate(7498%) hue-rotate(80deg) brightness(112%) contrast(101%);
          filter: invert(100%) sepia(1%) saturate(7498%) hue-rotate(80deg) brightness(112%) contrast(101%);
}
.hero-image {
  position: relative;
  height: 21.35vw;
  margin-top: 50px;
  color: #ffffff;
  min-height: 250px;
  z-index: -1;
  background-size: 100% 100%;
}

.hero-title {
  position: absolute;
  top: 50%;
  height: 120px;
  margin-top: -32px;
  color: #ffffff;
}

@media screen and (max-width: 576px) {
  .hero-title {
    /* position: absolute;
    top: 50%;
    left: 50%;
    height: 120px; */
    /* margin-top: -32px; */
    color: #ffffff;
    margin:0 auto;
    /* left: 50%; */
    /* margin-left: -50px; */
  }

  .hero-image {
    background-size: 170% 100%;
  }

}


.gradient-line {
  height: 15px;
  background: #8ec540;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#8ec540), to(#2176bc));
  background: linear-gradient(to right, #8ec540, #2176bc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.opaque-fill {
  z-index: 2;
  height: 54vh;
  margin-top: -27vh;
  background-color: red;
  margin-left: 50px;
  margin-right: 50px;
  opacity: 0.5;
  border-radius: 20px;
  border-style: solid;
  border-width: 5px;
  border-color: 8ec540;
}
.icon::after {
  font-family: 'FontAwesome';
  color: #ffffff;
}

.location-icon::after {
  content: '\f041';
}
.whatsapp-icon::after {
  content: '\f232';
}
.phone-icon::after {
  content: '\f095';
}
.mail-icon::after {
  content: '\f0e0';
}

.form-style {
  padding-left: 15px;
  padding-right: 15px;  
}

.no-pad {
  padding-left: 0px;
  padding-right: 0px;
}

.paderino {
  padding-left: 15px;
  padding-right: 15px;
}

.choice-attribute {
  padding: 1.5vw;
}

.choice-image {
  width: 25vw;
  height: 20vw;
  max-width: 180px;
  max-height: 200px;
}

.choice-attribute:hover {
  box-shadow: 0px 4.5px 14.9px 1.1px rgba(0, 0, 0, 0.21);
}

.manage-select {
  border-radius: 5px;
  border-style: solid;
  border-width: 3.5px;
  border-color: #2176bc;
  background: #DEDEDE;
}

.service-card {
  margin: auto;
  max-width: 300px;
  border-radius: 4px;
  padding: 24px 36px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.service-card:hover {
  box-shadow: 0px 4.5px 14.9px 1.1px rgba(0, 0, 0, 0.21);
}

.choice-text {
  margin: 5px;
  font-weight: 800px;
  color: #2176bc;
  font-weight: bold;
}

.empty-form {
  border-color: red;
}

@media screen and (max-width: 576px) {
  .choice-image {
    height: 16vw;
    width: 15vw;
  }

  .choice-text {
    line-height: 2em;
    font-size: 1.9vw;
  }

  .choice-attribute {
    padding-top: 4vw;
    padding-bottom: 4vw;
  }

  .minimized {
    font-size: 4.5vw;
  }

  .manage-select {
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: #2176bc;
    background: #DEDEDE;
  }

}

.carousel-content {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.lg-responsive {
  font-size:3vw;
}

.md-responsive {
  font-size:2vw;
}

/* .carousel .slide img {
  width: 100% !important;
  max-height: 66vw !important; 
}  to tinker with the height*/
.reviews__wrapper {
  margin-bottom: 2rem;
  font-family: 'Poppins', sans-serif;
}

.reviews__header {
  text-align: center;
  font-size: 60px;
  line-height: 60px;
  font-weight: 500;
  padding: 206px 0 50px;
  text-transform: uppercase;
}

.reviews__container {
  padding: 12rem 15px 10rem;
}

.reviews__row {
  justify-content: space-between;
  align-items: center;
}

.reviews__row .col {
  padding-left: 10px;
  padding-right: 10px;
}

.reviews__row > .col:first-child {
  flex: 0 0 25%;
}

.reviews__row > .col:last-child {
  position: relative;
}

.reviews__row > .col:last-child::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3rem;
  width: 232px;
  height: 1px;
  background: #cacaca;
  margin: 0 auto;
}

.reviews__title {
  color: #1376bc;
  font-weight: 600;
  line-height: 60px;
  /* margin-bottom: 20px; */
}

.reviews__description {
  font-weight: 300;
  font-size: 16px;
  line-height: 37px;
}

.review__card {
  background: white;
  border-radius: 5px;
  padding: 25px;
  box-shadow: 0 17px 25px 5px #ebebeb;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.review__image {
  margin-bottom: 18px;
}

.review__image img {
  height: 55px;
  width: 55px;
}

.review__name {
  font-size: 22px;
  font-weight: 500;
  line-height: 37px;
  margin-bottom: 9px;
}

.review__role {
  font-weight: 300;
  font-style: italic;
  font-size: 16px;
  line-height: 37px;
  margin-bottom: 9px;
}

.review__description {
  font-weight: 300;
  font-size: 14px;
  line-height: 27px;
  margin-bottom: 9px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.review__company {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
     -moz-column-gap: 15px;
          column-gap: 15px;
}

.review__company span {
  font-weight: 250;
  font-size: 14px;
  line-height: 37px;
}

span {
  font-size: inherit;
  line-height: inherit;
}

@media only screen and (max-width: 1365px) {
  .reviews__row {
    flex-direction: column;
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .reviews__title {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .reviews__row .col {
    padding: 0 15px;
  }

  .reviews__row .col:last-child .row {
    flex-direction: column;
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .reviews__container {
    padding: 156px 0;
  }

  .review__image {
    margin-bottom: 1rem;
  }

  .review__name {
    margin-bottom: 0;
  }

  .review__role {
    font-size: 14px;
  }

  .review__description {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .reviews__header {
    padding: 6rem 0;
    font-size: 32px;
  }

  .reviews__description {
    font-size: 3vw;
    line-height: 2em;
  }

  .reviews__container {
    padding: 0 15px;
  }
}

.data__wrapper {
  font-family: 'Poppins', sans-serif;
  margin: 0 0 6rem;
}

.data__header {
  text-align: center;
  font-size: 60px;
  line-height: 60px;
  font-weight: 500;
  padding: 206px 0 50px;
  text-transform: uppercase;
}

.data__wrapper > div:last-child {
  padding: 8rem 0;
  background: url(/static/media/data-background.7e341e2e.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.data__wrapper .container > div:first-child {
  text-align: center;
  max-width: 40rem;
  margin: 0 auto;
}

.data__wrapper .container .row {
  grid-column-gap: 8rem;
  -webkit-column-gap: 8rem;
     -moz-column-gap: 8rem;
          column-gap: 8rem;
}

.data__title {
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 8px;
}

.data__title span:last-child {
  color: #1376bc;
}

.data__description {
  font-weight: 400;
  font-size: 18px;
  line-height: 37px;
}

.feature__header > div:first-child {
  display: flex;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
  align-items: center;
  margin-bottom: 10px;
}

.feature__header > div:first-child img {
  height: 127px;
  width: 127px;
}

.feature__header > div:first-child span {
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
}

.text-green {
  color: #8ec641;
}

.text-blue {
  color: #1376bc;
}

.feature__title {
  font-size: 22px;
  line-height: 37px;
  margin-bottom: 18px;
}

.feature__title span:first-child {
  font-weight: 600;
}

.feature__title span:last-child {
  font-weight: 500;
}

.feature__description {
  font-weight: 300;
  font-size: 18px;
  line-height: 37px;
}

@media only screen and (max-width: 1365px) {
  .feature__description {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1023px) {
  .data__wrapper .container .row {
    flex-direction: column;
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .data__wrapper .container > div:first-child {
    margin-bottom: 4rem;
  }

  .data__wrapper > div:last-child {
    background: none;
  }
}

@media only screen and (max-width: 767px) {
  .data__wrapper > div:last-child {
    background: none;
    padding: 0;
    padding-bottom: 4rem;
  }

  .data__header {
    padding: 6rem 0;
    font-size: 32px;
  }

  .data__description {
    font-size: 3vw;
    line-height: 2em;
  }

  .feature__header > div:first-child img {
    width: 66px;
    height: 66px;
  }

  .feature__header > div:first-child span {
    font-size: 30px;
  }

  .feature__title {
    margin-bottom: 5px;
  }

  .feature__description {
    font-size: 3vw;
    line-height: 2em;
  }
}

.block {
  display: block;
  width: 35%;
  border: none;
  background-color: #4CAF50;
  color: white;
  padding: 1vw 2vw;
  font-size: 1vw;
  cursor: pointer;
  text-align: center;
  max-width: 300px;
  margin: auto;
  border-radius: 10px;
}

.block:hover {
  background-color: #ddd;
  color: black;
}

.vision {
  font-weight: bold;
  font-style: italic;
  color: #8ec540;
  font-size: 20px;
}

@media screen and (max-width: 576px) {

  .vision {
    font-size: 4vw;
  }

}
.service-card {
  margin: auto;
  max-width: 300px;
  border-radius: 4px;
  padding: 24px 36px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.service-card:hover {
  box-shadow: 0px 4.5px 14.9px 1.1px rgba(0, 0, 0, 0.21);
}

.maxLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; /* number of lines to show */
}
.Collapsible {
  border-bottom: 1px solid #6b6b6b;
  padding: 0.5em 0;
  cursor: pointer;
}

.Collapsible__trigger {
  display: block;
  position: relative;
  font-weight: 600;
  font-size: 18px;
  margin: 0.25em 0;
}

.Collapsible__trigger::after {
  font-family: 'FontAwesome';
  content: '\f107';
  position: absolute;
  display: block;
  top: 4px;
  right: 10px;
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}

.Collapsible__trigger.is-open::after {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}

.Collapsible__contentInner {
  padding: 0.5em 0 0;
  color: #6b6b6b;
}

.locations {
  height: 240px;
  opacity: 0.9;
}

.center-static {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-card {
  padding: 100px;
}

.quote-icon::after {
  font-family: 'FontAwesome';
  font-size: 72px;
  content: '\f10e';
}


.push-top {
  margin-top: -50px;
  font-size: 22px;
  color: #707070;
}

.circle-container {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background: transparent linear-gradient(177deg, #258bd3 0%, #ece9e6 70%) 0% 0%
    no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.matt {
  color: #707070;
  font-weight: bold;
}

.matt-border {
  border: 3px solid #707070;
  width: 375px;
  height: 58px;
  border-radius: 4px;
  display: flex;
}

.login-input {
  flex: 0.8 1;
  margin-left: 10px;
  border: none;
  background-color: rgba(100, 100, 100, 0);
}

.login-input::-moz-selection {
  background: #ffb7b7;
}

.login-input::selection {
  background: #ffb7b7;
}

.hide-border {
  border: none;
}

.default-container {
  width: 378px;
  height: 58px;
  background: transparent
    -webkit-gradient(linear, left top, right top, from(var(--unnamed-color-8ec641)), to(#258bd3)) 0% 0%
    no-repeat padding-box;
  background: transparent
    linear-gradient(90deg, var(--unnamed-color-8ec641) 0%, #258bd3 100%) 0% 0%
    no-repeat padding-box;
  background: transparent -webkit-gradient(linear, left top, right top, from(#8ec641), to(#258bd3)) 0% 0%
    no-repeat padding-box;
  background: transparent linear-gradient(90deg, #8ec641 0%, #258bd3 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-white {
  margin-top: 15px;
  color: white;
  font-weight: bold;
}

.menu-container {
  width: 100%;
}

.picker-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.portal-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  padding: 20px;
}

.gradient-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -46px;
  width: 100%;
  height: 46px;
  background: transparent
    linear-gradient(89deg, var(--unnamed-color-8ec641) 0%, #258bd3 100%) 0% 0%
    no-repeat padding-box;
  background: transparent linear-gradient(89deg, #8ec641 0%, #258bd3 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
  font-weight: bold;
  margin-left: -10px;
}

.sj-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
}

.inner-container {
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: 500px;
}

.sjm-container {
  margin-top: 20px;
  min-width: 493px;
  height: 50px;
  background: transparent -webkit-gradient(linear, left top, right top, from(#f81d1d45), to(#f1f10e4a))
    0% 0% no-repeat padding-box;
  background: transparent linear-gradient(90deg, #f81d1d45 0%, #f1f10e4a 100%)
    0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  white-space: nowrap;
}

.sj-row {
  margin-top: 20px;
  background: transparent -webkit-gradient(linear, left top, right top, from(#f81d1d45), to(#f1f10e4a))
    0% 0% no-repeat padding-box;
  background: transparent linear-gradient(90deg, #f81d1d45 0%, #f1f10e4a 100%)
    0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sj-row-part {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
  font-weight: bold;
  font-size: 12px;
  max-width: 300x;
  text-overflow: ellipsis;
  /* word */
  /* justify-items: center;
    align-items: center; */
}

.borderRight {
  border-right: 2px solid #4e4545;
}

.borderLeft {
  border-left: 2px solid #4e4545;
}

@media screen and (max-width: 576px) {
  .sj-row {
    flex-direction: column;
    border: 1px solid black;
  }

  .borderRight {
    border-right: 0px;
    border-bottom: 1px solid #4e4545;
  }

  .borderLeft {
    border-left: 0px;
    border-top: 1px solid #4e4545;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}


.filter-container {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  padding: 5px;
  border: dashed 0.5px #4e4545;
  border-radius: 5px;
}

.switch-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
}

.filter-text {
  font-weight: 700;
  font-size: 14px;
  color: #4e4545;
}

.level-filter-container {
  margin-top: -8px;
}
.level-switch {
  margin-top: 5px;
}

.make-border {
  border: solid 1px black;
}

.filter-form-container {
  width: 200px;
  height: 48px;
}

.filter-date-container {
  display: flex;
  flex-direction: column;
  padding: 3px;
  border: dashed 0.5px #4e4545;
  border-radius: 5px;
  flex-wrap: wrap;
}

.date-picker-section-container {
  display: flex;
  flex-direction: row;
  margin-top: -25px;
  padding: 8px 1px 1px 1px;
  flex-wrap: wrap;
}

.date-picker-container {
  display: flex;
  flex-direction: column;
}

.react-datepicker__input-container {
  display: block;
}

.react-datepicker-wrapper {
  width: 100%;
}

.level-date-picker {
  margin-bottom: 0px;
}

.date-switch-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 5px;
  padding-right: 5px;
  flex-wrap: wrap;
}


.list-group-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  word-wrap: inherit;
}

.create-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.underlined {
  -webkit-text-decoration: underline #659c18;
          text-decoration: underline #659c18;
}

.c-i-container {
  display: flex;
  min-width: 600px;
  margin-right: 50px;
}

.j-r {
  text-align: right;
}

.lhs-c-i {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: flex-end;
}

.rhs-c-i {
  display: flex;
  flex: 1 1;
  justify-items: flex-start;
}

.c-text {
  color: #4e4545;
  font-weight: bold;
  font-size: 21px;
}

.c-f-select {
  width: 247px;
  height: 48px;
}

.drop-box {
  width: 400px;
  height: 200px;
  border: dashed 2px gray;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-upload {
  border: 2px dashed gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  height: 200px;
  width: 380px;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-s-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.circle {
  height: 25px;
  width: 25px;
  border: solid gray 1px;
  padding: 3px;
  display: flex;
  border-radius: 50%;
}

.inner-circle {
  flex: 1 1;
  border-radius: 50%;
}

.selected-c {
  background-color: lightblue;
}

.dogerino .modal-content {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
}

.sj-row-blue {
  margin-top: 20px;
  background: transparent -webkit-gradient(linear, left top, right top, from(#258bd345), to(#8ec6414a))
    0% 0% no-repeat padding-box;
  background: transparent linear-gradient(90deg, #258bd345 0%, #8ec6414a 100%)
    0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 576px) {
  .sj-row-blue {
    flex-direction: column;
    border: 1px solid black;
  }
}

/* // 1 */
.stk-row-container {
  margin-top: 20px;
  background: transparent -webkit-gradient(linear, left top, right top, from(#f2709c), to(#ff9472)) 0% 0%
    no-repeat padding-box;
  background: transparent linear-gradient(90deg, #f2709c 0%, #ff9472 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* // 2 */
.stk-row-part {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
  font-weight: bold;
  font-size: 12px;
  max-width: 300x;
  text-overflow: ellipsis;
}

.filter-option-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .stk-row-container {
    flex-direction: column;
    border: 1px solid black;
  }
}

.sjp-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.sjp-item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px;
}


